// 教学中心
<template>
    <div class="teaching_center">
        教学中心
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    computed: {},
    components: {},
};
</script>
<style lang="scss" scoped>
.teaching_center {
}
</style>